.section-image-container {
  justify-content: center;
  display: flex;
  position: inherit;
  flex-direction: column;
  align-items: center;
}
.font-15 {
  font-size: 15px;
}
